import { ICategory } from '@/shared/model/category.model';
import { IAppUser } from '@/shared/model/app-user.model';
import { IInsuranceOption } from '@/shared/model/insurance-option.model';

export interface IInsurance {
  id?: number;
  description?: string;
  deletedTimestamp?: Date | null;
  category?: ICategory;
  user?: IAppUser;
  options?: IInsuranceOption[] | null;
  isRequired: boolean | null;
}

export class Insurance implements IInsurance {
  constructor(
    public id?: number,
    public description?: string,
    public deletedTimestamp?: Date | null,
    public category?: ICategory,
    public user?: IAppUser,
    public options?: IInsuranceOption[] | null,
    public isRequired: boolean | null = false
  ) {}
}
