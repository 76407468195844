import { IInsurance } from '@/shared/model/insurance.model';
import { IPercentage } from '@/shared/model/percentage.model';
import { IAppUser } from '@/shared/model/app-user.model';

export interface IInsuranceOption {
  id?: number;
  description?: string;
  deletedTimestamp?: Date | null;
  insurance?: IInsurance;
  percentage?: IPercentage;
  warning?: boolean;
  defaultComment?: string;
  user?: IAppUser;
}

export class InsuranceOption implements IInsuranceOption {
  constructor(
    public id?: number,
    public description?: string,
    public deletedTimestamp?: Date | null,
    public insurance?: IInsurance,
    public percentage?: IPercentage,
    public warning?: boolean,
    public defaultComment?: string,
    public user?: IAppUser
  ) {}
}
